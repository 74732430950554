import { UrlSegment } from '@angular/router';

export const generateUrlWithLanguage = (url: string, language: string): string => {
  if (language === 'en' && !url.startsWith('/en')) {
    return `/en${url}`;
  } else if (language === 'pl' && url.startsWith('/en')) {
    return url.replace(/^\/en/, '');
  }
  return url;
};

export const langPrefixMatcher = (url: UrlSegment[]) => {
  if (!url.length) {
    return {
      consumed: []
    };
  }

  const prefix = url?.[0].path;
  const consumed = prefix === 'en' ? [url?.[0]] : [];

  return {
    consumed,
    posParams: { lang: url?.[0] }
  };
};
