import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { catchError, map } from 'rxjs';

import { CategorySeoService } from './category-seo.service';
import { CategoryService } from './category.service';

export const categoryResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const categorySeoService = inject(CategorySeoService);
  const categoryService = inject(CategoryService);
  const router = inject(Router);

  const categoryId = route?.params?.categoryId;
  const lang = getLangFromRouteParams(route?.params);
  const queryParams = route?.queryParams;
  const category$ = categoryService.get({ id: categoryId, language: lang });

  return category$.pipe(
    map((categories) => {
      const category = categories?.find((c) => +c?.id === +categoryId);
      const currentCategory = {
        ...category,
        description: category?.description
          ?.replace(/<b>/gim, '<strong>')
          .replace(/<\/b>/gim, '</strong>')
      };
      const page = +queryParams?.page || 1;
      categorySeoService.setSeo(currentCategory, page, lang);

      // const currentUrl = state.url;
      // const correctUrl = `${lang === 'en' ? '/en' : ''}/${categoryId}-${currentCategory?.slug}`;
      // const params = new URLSearchParams(queryParams);
      // const correctUrlWithParams = `${correctUrl}${
      //   Object.keys(queryParams)?.length ? '?' + params.toString() : ''
      // }`;

      // if (currentUrl !== correctUrlWithParams) {
      //   console.log(1);
      //   router.navigateByUrl(correctUrlWithParams);
      // }

      return {
        categoryId,
        categories,
        currentCategory,
        lang,
        page
      };
    }),
    catchError((error) => {
      console.log('🚀 ~ file: category.resolver.ts:50 ~ catchError ~ error:', error);
      return router.navigateByUrl('/not-found');
    })
  );
};
