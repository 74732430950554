import { UrlSegment } from '@angular/router';
import { Category, checkIsCategory, config } from '@manzuko/shared';

export const categoryUrlMatcher = (url: UrlSegment[]) => {
  if (!checkIsCategory(url)) {
    return null;
  }

  const categoryId = new UrlSegment(url[0].path.match(/^(\d+)/gm)[0], {});
  return {
    consumed: url,
    posParams: { categoryId }
  };
};

export const categoryPath = (
  { id, slug, slugPl, slugEn }: Partial<Category> = {},
  lang?: string
): string => {
  if (lang === 'en') {
    return `/en/${id || 0}-${slugEn || slug || ''}`;
  }
  if (slugPl) {
    return `/${id || 0}-${slugPl || ''}`;
  }
  return `/${id || 0}-${slug || ''}`;
};

export const categorySeoUrl = (
  category: Partial<Category> = {},
  page = 1,
  lang?: string
): string => {
  return `${config.website.url}${categoryPath(category, lang)}${page > 1 ? `?page=${page}` : ''}`;
};

export const categoryWithUrls = (category: Category, lang?: string): Category => {
  // console.log('🚀 ~ file: category.helpers.ts:46 ~ categoryWithUrls ~ category:', category);
  return {
    ...category,
    path: categoryPath(category, lang)
    // url: categorySeoUrl(category, lang),
    // urlPl: categorySeoUrl(category, 'pl'),
    // urlEn: categorySeoUrl(category, 'en')
  };
};

export const getBreadcrumbs = (categories, currentCategory, language) => {
  const breadcrumbs = [];
  let current = currentCategory;
  breadcrumbs.push({
    ...categoryWithUrls(current, language)
  });
  while (current?.parentId > 2) {
    current = categoryWithUrls(categories?.find((c) => c?.id === current.parentId), language);
    breadcrumbs.push({ ...current });
  }
  breadcrumbs.push({
    displayName: 'Manzuko',
    name: 'Manzuko',
    path: '/'
  });
  return breadcrumbs.reverse();
};

export const getSubcategories = (categories, parentId, language) => {
  return categories
    ?.filter((category) => category?.parentId === +parentId)
    .map((category) => categoryWithUrls(category, language));
};

export const getDescriptionIfFirstPage = (page, description) =>
  +page === 1 || !page ? description : null;
