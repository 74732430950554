import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { getQuantityDiscounts } from '@manzuko/shared/utils/product.helpers';
import { map, switchMap } from 'rxjs';

import { CategoryService } from 'app/data-access/category/category.service';
import { ProductService } from 'app/data-access/product';

import { ProductSeoService } from './product-seo.service';

export const productResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const categoryService = inject(CategoryService);
  const productsService = inject(ProductService);
  const productsSeoService = inject(ProductSeoService);
  const router = inject(Router);

  const language = getLangFromRouteParams(route?.params);
  const productId = +route?.params?.productId;
  const product = productsService.getOne({ id: productId, language });

  return product.pipe(
    switchMap((product) =>
      categoryService.get({ id: product?.categoryId, language }).pipe(
        map((categories) => {
          const productCategory = categories?.find(
            (category) => category?.id === product?.categoryId
          );
          productsSeoService.setSeo(product, language);

          // const currentUrl = state?.url;
          // const correctUrl = `${
          //   language === 'en' ? '/en' : ''
          // }/${productCategory?.slug}/${product?.id}-${product?.slug}.html`;

          // if (currentUrl !== correctUrl) {
          //   router.navigateByUrl(correctUrl);
          // }

          return {
            product: {
              ...product,
              quantityDiscounts: getQuantityDiscounts(product, product?.discounts),
              descriptionShort: product?.descriptionShort?.replace(/<\/?h1[^>]*>/g, '')
            },
            language,
            categories,
            productCategory
          };
        })
      )
    )
  );
};
