import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { catchError, EMPTY, map } from 'rxjs';

import { GroupSeoService } from './group-seo.service';
import { GroupService } from './group.service';

export const groupResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
  // state: RouterStateSnapshot
) => {
  const groupSeoService = inject(GroupSeoService);
  const groupService = inject(GroupService);
  // const router = inject(Router);

  const groupId = route?.params?.id;
  const lang = getLangFromRouteParams(route?.params);
  const queryParams = route?.queryParams;
  const group$ = groupService.get({ id: groupId, language: lang });

  return group$.pipe(
    map((group) => {
      const page = +queryParams?.page || 1;
      groupSeoService.setSeo(group, page, lang);

      // const currentUrl = state.url;
      // const correctUrl = `${lang === 'en' ? '/en' : ''}/${groupId}-${currentGroup?.slug}`;
      // const params = new URLSearchParams(queryParams);
      // const correctUrlWithParams = `${correctUrl}${
      //   Object.keys(queryParams)?.length ? '?' + params.toString() : ''
      // }`;

      // if (currentUrl !== correctUrlWithParams) {
      //   console.log(1);
      //   router.navigateByUrl(correctUrlWithParams);
      // }

      return {
        groupId,
        group,
        lang,
        page
      };
    }),
    catchError((error) => {
      console.log('🚀 ~ file: group.resolver.ts:50 ~ catchError ~ error:', error);
      return EMPTY;
      // return router.navigateByUrl('/not-found');
    })
  );
};
