export const config = {
  latestDays: 60,
  lastItems: 6,
  freeDelivery: {
    en: 40,
    pl: 160
  },
  freeGift: {
    en: 23,
    pl: 69
  },
  defaultDaysForStats: 30,
  productsPageSize: 24,
  organization: {
    address: {
      '@type': 'PostalAddress',
      postalCode: '77-300',
      streetAddress: 'Rzemieślnicza 29',
      addressCountry: 'Poland',
      telephone: '+48 733-885-808'
    },
    email: 'zukomanzuko@gmail.com',
    telephone: '+48 733-885-808'
  },
  website: {
    name: 'Manzuko',
    url: 'https://manzuko.com'
  },
  logo: 'https://manzuko.b-cdn.net/assets/images/logo-text-white.jpg',
  geo: {
    latitude: '53.675909',
    longitude: '17.3639249'
  },
  openingHoursSpecification: {
    validFrom: '',
    opens: '07:00',
    closes: '15:00',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  },
  merchantReturnDays: 14,
  shippingRate: {
    '@type': 'MonetaryAmount',
    minValue: 0,
    maxValue: 49,
    currency: 'PLN'
  },
  deliveryTime: {
    '@type': 'ShippingDeliveryTime',
    handlingTime: {
      '@type': 'QuantitativeValue',
      minValue: 0,
      maxValue: 1,
      unitCode: 'DAY'
    },
    transitTime: {
      '@type': 'QuantitativeValue',
      minValue: 1,
      maxValue: 5,
      unitCode: 'DAY'
    }
  },

  cacheFolderPath: 'dist/manzuko/browser/cache',

  imagesSizes: {
    product: {
      cart: {
        width: 126,
        height: 126
      },
      small: {
        width: 80,
        height: 80
      },
      medium: {
        width: 500,
        height: 500
      },
      home: {
        width: 380,
        height: 380
      },
      large: {
        width: 1000,
        height: 1000
      }
    }
  },

  orderStatus: {
    // WAITING_FOR_CHECK: 1,
    PAYMENT_ACCEPTED: 2,
    PREPARING: 3,
    SENT: 4,
    // DELIVERED: 5,
    CANCELED: 6,
    RETURNED: 7,
    // PAYMENT_ERROR: 8,
    // OUT_OF_STOCK: 9,
    WAITING_FOR_BANK_TRANSFER: 10,
    // WAITING_FOR_PAYPAL: 11,
    // PAYMENT_RECEIVED: 12,
    CASH_ON_DELIVERY_RECEIVED: 13,
    WAITING_FOR_PREPARING: 14,
    // WAITING_FOR_DETAILS: 15,
    // WAITING_FOR_PAYMENT_ACCEPTANCE: 16,
    // RETURN_ACCEPTED: 17,
    // P24_1_WAITING: 18,
    // P24_1_ACCEPTED: 19,
    // P24_1_CHANGED: 20,
    P24_WAITING: 21,
    P24_ACCEPTED: 22
    // P24_2_ACCEPTED: 23
  },
  OrderReturnState: {
    WAITING_FOR_ACCEPT: 1,
    WAITING_FOR_PACKAGE: 2,
    PACKAGE_RECEIVED: 3,
    DECLINED: 4,
    ACCEPTEd: 5
  },

  pixelToken:
    'EAAJlNuIFAX8BO8KA7jDPJkOqFOpCYZAZByqM6eaFwH2so9yBKSGtysKuuV3gvkxMEtBZB41BOO0ZCpDZBO4GqOM8ZCxSyVZC7TEiKbGXpATSIYizSpAkCbzQYFfQ28A3VQRb46UuMNvlgtyPE1ZB46kHBEhZBGTUtCQd4qZClM5P5wxhK0C8cI0pUlljwadhaScZA4W7gZDZD'
};

export const configStateNames = {
  // [config.orderStatus.WAITING_FOR_CHECK]: 'Oczekiwanie płatności czekiem',
  [config.orderStatus.PAYMENT_ACCEPTED]: 'Płatność zaakceptowana',
  [config.orderStatus.PREPARING]: 'Przygotowanie w toku',
  [config.orderStatus.SENT]: 'Wysłane',
  // [config.orderStatus.DELIVERED]: 'Dostarczone',
  [config.orderStatus.CANCELED]: 'Anulowane',
  [config.orderStatus.RETURNED]: 'Zwrot',
  // [config.orderStatus.PAYMENT_ERROR]: 'Błąd płatności',
  // [config.orderStatus.OUT_OF_STOCK]: 'Brak towaru',
  [config.orderStatus.WAITING_FOR_BANK_TRANSFER]: 'Oczekiwanie na przelew',
  // [config.orderStatus.WAITING_FOR_PAYPAL]: 'Oczekiwanie na płatność paypal',
  // [config.orderStatus.PAYMENT_RECEIVED]: 'Płatność przyjęta', // 'Płatność otrzymana',
  [config.orderStatus.CASH_ON_DELIVERY_RECEIVED]: 'Pobranie wpłacone',
  [config.orderStatus.WAITING_FOR_PREPARING]: 'Oczekiwanie na przygotowanie pobrania',
  // [config.orderStatus.WAITING_FOR_DETAILS]: 'Oczekiwanie na dodatkowe informacje',
  // [config.orderStatus.WAITING_FOR_PAYMENT_ACCEPTANCE]: 'Oczekiwanie na zaakceptowanie płatności',
  // [config.orderStatus.RETURN_ACCEPTED]: 'Zwrot Zaakceptowany',
  // [config.orderStatus.P24_1_WAITING]: 'Oczekwianie na płatność Przelewy24 1',
  // [config.orderStatus.P24_1_ACCEPTED]: 'Płatność Przelewy24 przyjęta 1',
  // [config.orderStatus.P24_1_CHANGED]: 'Przelewy24 zmienione',
  [config.orderStatus.P24_WAITING]: 'Oczekiwanie na płatność Przelewy24',
  [config.orderStatus.P24_ACCEPTED]: 'Płatność Przelewy24 przyjęta' // 'Płatność Przelewy24 przyjęta',
  // [config.orderStatus.P24_2_ACCEPTED]: 'Płatność Przelewy24 przyjęta 2'
};

export const configOrderStatesAdmin = Object.entries(configStateNames).map(
  ([value, viewValue]) => ({
    value: Number(value),
    viewValue
  })
);

export const configStateColors = {
  // [config.orderStatus.WAITING_FOR_CHECK]: 'text-red-500',
  [config.orderStatus.PAYMENT_ACCEPTED]: 'text-green-600',
  [config.orderStatus.PREPARING]: 'text-red-600',
  [config.orderStatus.SENT]: 'text-green-700',
  // [config.orderStatus.DELIVERED]: 'text-green-600',
  [config.orderStatus.CANCELED]: 'text-red-600',
  [config.orderStatus.RETURNED]: 'text-green-600',
  // [config.orderStatus.PAYMENT_ERROR]: 'text-red-600',
  // [config.orderStatus.OUT_OF_STOCK]: 'text-red-600',
  [config.orderStatus.WAITING_FOR_BANK_TRANSFER]: 'text-red-600',
  // [config.orderStatus.WAITING_FOR_PAYPAL]: 'text-red-600',
  // [config.orderStatus.PAYMENT_RECEIVED]: 'text-green-600',
  [config.orderStatus.CASH_ON_DELIVERY_RECEIVED]: 'text-green-600',
  [config.orderStatus.WAITING_FOR_PREPARING]: 'text-red-600',
  // [config.orderStatus.WAITING_FOR_DETAILS]: 'text-red-600',
  // [config.orderStatus.WAITING_FOR_PAYMENT_ACCEPTANCE]: 'text-red-600',
  // [config.orderStatus.RETURN_ACCEPTED]: 'text-green-600',
  // [config.orderStatus.P24_1_WAITING]: 'text-red-600',
  // [config.orderStatus.P24_1_ACCEPTED]: 'text-green-600',
  // [config.orderStatus.P24_1_CHANGED]: 'text-red-600',
  [config.orderStatus.P24_WAITING]: 'text-red-600',
  [config.orderStatus.P24_ACCEPTED]: 'text-green-600'
  // [config.orderStatus.P24_2_ACCEPTED]: 'text-green-600'
};

export const returnStates = [
  { value: 'created', label: 'Utworzony' },
  { value: 'waitingForPackage', label: 'Oczekiwanie na przesyłkę', class: 'bg-yellow-200' },
  {
    value: 'accepted',
    label: 'Zaakceptowane',
    class: 'bg-green-400',
    description: 'Paczka zwrotna otrzymana, ale pieniądze nie zostały jeszcze wysłane'
  },
  {
    value: 'rejected',
    label: 'Odrzucone',
    class: 'bg-red-300'
  },
  {
    value: 'done',
    label: 'Zrealizowane',
    class: 'bg-green-600',
    description: 'Zwrot został zaakceptowany, a pieniądze zostały odesłane do klienta'
  },
  {
    value: 'cancelled',
    label: 'Anulowane',
    class: 'bg-red-700',
    description: 'Błąd/ pomyłka'
  }
];

export enum ShippingId {
  inPostPaczkomaty = 98,
  inPostKurier = 104,
  inPostKurierPobranie = 105,
  pocztaPolskaOdbiorWPunkcie = 106,
  pocztaPolska = 107,
  pocztaPolskaPobranie = 108,
  DPD = 109,
  DPDpobranie = 110,
  DPDwysylkaZagraniczna = 111,
  wysylkaElektroniczna = 120,
  orlen = 121,
  orlenPobranie = 122
}

export enum BannerTypes {
  super = 1,
  creative = 2,
  happy = 3,
  night = 4,
  other = 5
}

export enum IsrTitle {
  product = 1,
  category = 2,
  home = 3,
  content = 4,
  contact = 5,
  newProducts = 6,
  discount = 7,
  bestseller = 8,
  group = 9,
  tag = 10,
  search = 11
}

export enum IsrSort {
  id = 1,
  createDate = 2,
  reference = 3,
  price = 4
}

export enum IsrOrder {
  asc = 1,
  desc = 2
}

export enum IsrLanguage {
  pl = 1,
  en = 2
}
