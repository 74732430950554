<header class="bg-white" *transloco="let t">
  <div
    data-test="header-logo"
    class="p-2 flex flex-row justify-between items-center sm:px-4 md:px-6">
    <button
      type="button"
      title="Menu"
      class="md:hidden mr-1 text-neutral-500"
      (click)="toggleMenu()">
      <svg
        alt="Menu"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-10 h-10">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
      </svg>
    </button>
    <a class="max-md:hidden" [routerLink]="'/' | mzkLink | async" title="Strona główna - Manzuko">
      <img
        class="w-20 h-[38px] md:w-40 md:h-[76px]"
        src="https://manzuko.b-cdn.net/assets/images/logo/logo-text.svg"
        loading="eager"
        fetchpriority="high"
        alt="Sklep z Koralikami i Akcesoriami do Wyrobu Biżuterii - Półfabrykatami - Manzuko" />
    </a>
    <mzk-search data-test="search" class="min-h-11" />
    <div id="userbar" class="bg-white pt-4 pb-3 z-[101]">
      <ul class="flex justify-evenly items-center text-center text-brand-700 text-sm">
        <!-- LANG -->
        <li class="relative min-w-18 max-md:hidden" data-test="header-lang">
          <button
            type="button"
            (click)="langOpen.set(true)"
            (clickOutside)="langOpen.set(false)"
            class="mx-3">
            <fast-svg name="change-language" />
            <span>{{ t('shop.header.lang.' + activeLang()) }}</span>
          </button>
          <div class="absolute md:right-0 md:bottom-auto bottom-16">
            @if (langOpen()) {
              <div
                data-test="header-user-menu"
                class="w-48 bg-white border border-gray-200 rounded-md shadow-md z-50">
                @for (lang of availableLangs; track lang.id) {
                  <ul>
                    <li
                      class="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      (click)="setActiveLang(lang?.id)"
                      data-test="header-lang-button">
                      <button type="button">
                        <span class="flex items-center">
                          <ng-container
                            *ngTemplateOutlet="flagImage; context: { $implicit: lang?.id }"
                            (click)="langOpen.set(false)" />
                          <span class="ml-3" data-test="header-lang-label">
                            {{ lang?.label }}
                          </span>
                        </span>
                      </button>
                    </li>
                  </ul>
                }
              </div>
              <!-- Flag image template -->
              <ng-template let-lang #flagImage>
                <span class="relative w-6 shadow rounded-sm overflow-hidden">
                  <span
                    class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
                  <img
                    class="w-full"
                    [src]="'assets/images/flags/' + flagCodes[lang] + '.svg'"
                    [alt]="'Flag image for ' + lang"
                    loading="lazy" />
                </span>
              </ng-template>
            }
          </div>
        </li>
        <!-- User Menu -->
        <li class="relative sm:min-w-18" data-test="header-user">
          <mzk-customer-icon class="md:mx-3" />
        </li>
        <li
          data-test="header-favourite"
          [routerLink]="'/konto/ulubione' | mzkLink | async"
          class="relative min-w-18 max-md:hidden">
          <fast-svg name="favorite" />
          <span>
            {{ t('shop.header.favourite') }}
          </span>
        </li>
        <li data-test="header-cart" class="min-w-18 max-md:hidden">
          <mzk-cart-icon />
        </li>
      </ul>
    </div>
  </div>

  <ng-content />
</header>
