import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

import { BannerService } from 'app/data-access/banner/banner.service';
import { provideImageLoader } from 'app/data-access/image-loaders/image-loader';
import { selectLayoutLanguage } from 'app/data-access/layout';

import { RouterLinkPipe } from '../pipes';

@Component({
  standalone: true,
  selector: 'mzk-banners',
  templateUrl: './banners.component.html',
  imports: [AsyncPipe, RouterLink, NgOptimizedImage, RouterLinkPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideImageLoader({
      breakpoints: [
        320, 360, 395, 412, 440, 480, 520, 560, 600, 640, 665, 680, 720, 760, 800, 1024, 1080, 1200,
        1350, 1600, 1920
      ]
    })
  ]
})
export class BannersComponent {
  readonly #bannerService = inject(BannerService);
  readonly #store = inject(Store);

  protected readonly lang = this.#store.selectSignal(selectLayoutLanguage);

  protected readonly banners = toSignal(
    this.#bannerService.getValid().pipe(
      filter((banners) => banners?.length > 0),
      map((banners) =>
        banners.map((banner) => ({
          ...banner,
          locale: {
            ...banner.locale,
            pl: {
              ...banner.locale.pl,
              title: 'Aktualnie w promocji'
            },
            en: {
              ...banner.locale.pl,
              title: 'Current Discount'
            }
          },
          imagePath: banner?.imagePath
            ?.replace('https://manzuko.com', 'https://manzuko.b-cdn.net')
            ?.replace('&width=1500', '')
        }))
      )
    )
  );
}
