@if (banners(); as banners) {
  <div class="w-full flex flex-col md:space-x-1 md:flex-row">
    <!-- items-end pr-[5%] -->
    @for (banner of banners; track banner.id) {
      <a
        [routerLink]="banner?.url | mzkLink | async"
        class="flex flex-col w-full h-20 relative justify-center items-center sm:h-[150px] md:h-[200px]">
        <img
          class="w-full object-cover"
          [ngSrc]="banner?.imagePath"
          [alt]="banner?.locale?.[lang()]?.title || ''"
          [sizes]="
            '(max-width: 959px) 100vw, (min-width: 960px) ' + (100 / banners?.length - 1) + 'vw'
          "
          fill
          priority />
        <div
          class="flex flex-col w-full h-20 relative justify-center items-center mb-0.5 md:mb-1 sm:h-[150px] md:h-[200px]"
          [innerHTML]="banner?.locale?.[lang()]?.description"></div>
      </a>
    }
  </div>
}
