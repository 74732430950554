import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  private readonly meta = inject(Meta);
  private readonly title = inject(Title);
  private readonly document = inject(DOCUMENT);

  addPageTag({ name, content }): void {
    this.meta.addTag({ name, content });
  }

  removePageTag({ name }): void {
    this.meta.removeTag(`name='${name}'`);
  }

  setPageTitle(title: string): void {
    const sufix = 'Manzuko';
    const metaTitle = `${title}${title?.includes(sufix) ? '' : ` - ${sufix}`}`;
    this.title.setTitle(metaTitle);
  }

  getPageTitle(): string {
    return this.title.getTitle();
  }

  createLinkForCanonicalURL(url: string = this.document.URL): void {
    const link: HTMLElement = this.document.getElementById('metaCanonical');
    link.setAttribute('href', url);
  }

  createLinkForAlternatePl(url: string = this.document.URL): void {
    const link: HTMLElement = this.document.getElementById('metaAlternatePl');
    link.setAttribute('href', url);
  }

  createLinkForAlternateEn(url: string = this.document.URL): void {
    const link: HTMLElement = this.document.getElementById('metaAlternateEn');
    link.setAttribute('href', url);
  }

  createLinkForAlternateDefault(url: string = this.document.URL): void {
    const link: HTMLElement = this.document.getElementById('metaAlternateDefault');
    link.setAttribute('href', url);
    link.setAttribute('hreflang', 'x-default');
  }

  setDocumentLang(lang = 'pl'): void {
    this.document.documentElement.setAttribute('lang', lang);
  }

  updateMetaTags(metaTags: Record<string, string>): void {
    Object.entries(metaTags).forEach(([name, content]) => {
      this.meta.updateTag({ name, content });
    });
  }

  updateOgTags(ogTags: Record<string, string>): void {
    Object.entries(ogTags).forEach(([property, content]) => {
      this.meta.updateTag({ property, content });
    });
  }
}
