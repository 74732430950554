import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { delayWhen, interval } from 'rxjs';

import { selectCart } from 'app/data-access/cart';
import { CategoryActions } from 'app/data-access/category';
import { LayoutActions, selectLayoutShowCart } from 'app/data-access/layout';
import { selectSettingShowCookies } from 'app/data-access/setting/setting.selectors';
import { BannersComponent } from 'app/shared/banners/banners.component';
import { CartMicroComponent } from 'app/shared/cart-micro/cart-micro.component';
import { CartComponent } from 'app/shared/cart/cart.component';
import { CookiesComponent } from 'app/shared/cookies/cookies.component';
import { IfIsBrowserDirective } from 'app/shared/directives';
import { LoadingBarComponent } from 'app/shared/loading-bar/loading-bar.component';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavDesktopComponent } from './nav-desktop/nav-desktop.component';
import { NavComponent } from './nav/nav.component';

@Component({
  standalone: true,
  selector: 'mzk-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavDesktopComponent,
    BannersComponent,
    CartComponent,
    IfIsBrowserDirective,
    CookiesComponent,
    CartMicroComponent,
    LoadingBarComponent
  ]
})
export class LayoutComponent {
  readonly #observer = inject(BreakpointObserver);
  readonly #store = inject(Store);

  private readonly observerMobile = toSignal(this.#observer.observe('(max-width: 959px)'));
  private readonly observerDesktop = toSignal(this.#observer.observe('(min-width: 960px)'));

  protected readonly cart = this.#store.selectSignal(selectCart);
  protected readonly showCart = this.#store.selectSignal(selectLayoutShowCart);

  protected readonly showCookies = toSignal(
    this.#store
      .select(selectSettingShowCookies)
      .pipe(delayWhen((showCookies: boolean) => interval(showCookies ? 6000 : 0)))
  );
  protected readonly showAdvanced = signal(false);

  protected readonly isMobile = computed(() => this.observerMobile().matches);
  protected readonly isDesktop = computed(() => this.observerDesktop().matches);

  constructor() {
    this.#store.dispatch(CategoryActions.loadTopCategories());
  }

  hideCart() {
    this.#store.dispatch(LayoutActions.toggleCart({ showCart: false }));
  }
}
